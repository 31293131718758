import { useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import UseAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useAsyncError, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";

// Location//
import { useLocation, useNavigate } from "react-router-dom";
import { tr } from "date-fns/locale";

const AddUser = () => {
  ///// LOCATION /////
  const location = useLocation();
  const navigate = useNavigate();
  const user = useAuth();
  const pathArray = location.pathname.split("/");
  const { id } = useParams();
  const user_id = user.auth.user_details.id;
  const [inputs, setInputs] = useState({});
  const [did, setDid] = useState([]);
  const controller = new AbortController();
  const [channels, setChannels] = useState([]);

  //  -----------------  Validation ---------------- //
  const [required, setRequired] = useState({
    username: false,
    password: false,
    phone_number: false,
    call_option: false,
    call_mode: false,
    channel_id: false,
    did: false,
  });

  const [click, setClick] = useState({
    username: false,
    password: false,
    phone_number: false,
    call_option: false,
    call_mode: false,
    channel_id: false,
    did: false,
  });

  const [error, setError] = useState("");
  const [showdid, setShowDid] = useState(false);

  // API CALL //
  const { auth, setAuth } = useAuth();
  const axiosprivate = UseAxiosPrivate();
  useEffect(() => {
    console.log("Required ------------------", required);
    const fetchAgentUser = async () => {
      const agentId = parseInt(id);
      if (agentId != 0) {
        await axios
          .post(
            process.env.REACT_APP_API_URL + "/contactcentre/GetAgentUser/",
            {
              user_id: user_id,
              agent_id: agentId,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth?.accessToken}`,
              },
              withCredentials: true,
            }
          )
          .then((res) => {
            setInputs(res.data);
          })
          .catch((err) => console.log(err));
      }
    };
    fetchAgentUser();
    fetchChannelIds();
  }, []);

  // Get all Channele IDs

  const fetchChannelIds = async () => {
    const resposne = await axiosprivate.get(
      "/contactcentre/channel_id/" + user_id + "/",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth?.accessToken}`,
        },
      }
    );
    setChannels(resposne.data);
  };

  const fetDIDData = async () => {
    const response = await axiosprivate.get(
      "/DIDListById/" + user_id + "/" + inputs.channel_id + "/",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth?.accessToken}`,
        },
        withCredentials: true,
      }
    );
    setDid(response.data);
  };

  const Validation = (name) => {
    if (name === "agent_username") {
      setClick((prevState) => ({
        ...prevState,
        username: true,
      }));
    }
    if (name === "agent_password") {
      setClick((prevState) => ({
        ...prevState,
        password: true,
      }));
    }
    if (name === "phone_number") {
      setClick((prevState) => ({
        ...prevState,
        phone_number: true,
      }));
    }
  };

  useEffect(() => {
    if (inputs.channel_id !== undefined) {
      setShowDid(true);
    }

    // -------- Username -------- //
    if (
      inputs.agent_username === "" ||
      (inputs.agent_username === undefined && click.username === true)
    ) {
      setRequired((prevState) => ({
        ...prevState,
        username: true,
      }));
    } else {
      setRequired((prevState) => ({
        ...prevState,
        username: false,
      }));
    }

    // -------- Password ------- //
    if (
      inputs.agent_password === "" ||
      (inputs.agent_password === undefined && click.password === true)
    ) {
      setRequired((prevState) => ({ ...prevState, password: true }));
    } else {
      setRequired((prevState) => ({ ...prevState, password: false }));
    }

    //  ---------- Phone Number ---------  //
    if (
      inputs.phone_number === "" ||
      (inputs.phone_number === undefined && click.phone_number === true)
    ) {
      setRequired((prevState) => ({ ...prevState, phone_number: true }));
      setError("*Please Enter Phone Number");
    }
    const mobileNumberRegex = /^[0-9]{10}$/; // 10 digits numeric only
    const isValidMobileNumber = mobileNumberRegex.test(inputs.phone_number);

    if (isValidMobileNumber) {
      setRequired((prevState) => ({ ...prevState, phone_number: false }));
      setError("*Please Enter Valid Phone Number");
    } else {
      setRequired((prevState) => ({ ...prevState, phone_number: true }));
    }
  }, [inputs]);

  // ---------------------------  End ---------------------------------//

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((prevdata) => ({
      ...prevdata,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // ---------- Call Options --------//
    console.log(inputs.call_option, "************", inputs.call_mode);
    if (inputs.call_option === undefined) {
      setRequired((prevState) => ({ ...prevState, call_option: true }));
    } else {
      setRequired((prevState) => ({ ...prevState, call_option: false }));
    }

    // ------------ Call Mode ------------ //
    if (inputs.call_mode === undefined) {
      setRequired((prevState) => ({ ...prevState, call_mode: true }));
    } else {
      setRequired((prevState) => ({ ...prevState, call_mode: false }));
    }

    // ---------------- Channel Id -------------- //,
    if (inputs.channel_id === undefined) {
      setRequired((prevState) => ({ ...prevState, channel_id: true }));
    } else {
      setRequired((prevState) => ({ ...prevState, channel_id: false }));
    }

    //-------------------- DID ------------------ //

    if (inputs.did === undefined) {
      setRequired((prevState) => ({ ...prevState, did: true }));
    } else {
      setRequired((prevState) => ({ ...prevState, did: false }));
    }

    let post_data = {
      userId: user_id,
      agent_id: id,
      agent_username: inputs.agent_username,
      agent_password: inputs.agent_password,
      call_mode: inputs.call_mode,
      call_option: inputs.call_option,
      channel_id: inputs.channel_id,
      did: inputs.did,
      phone_number: inputs.phone_number,
    };

    const isAnyValueUndefined = Object.values(post_data).some(
      (value) => typeof value === "undefined" || value === ""
    );

    console.log(
      post_data,
      "----------------------------------------",
      isAnyValueUndefined
    );

    if (isAnyValueUndefined === true) {
      Swal.fire({
        icon: "warning",
        title: "Please add all mandatory fields",
      });
    } else {
      try {
        const response = await axiosprivate.post(
          "/contactcentre/agents/create/",
          post_data,
          {
            signal: controller.signal,
          }
        );

        if (response.data.type == "sucess") {
          Swal.fire({
            icon: "success",
            title: response.data.message,
          });
          navigate("/admin/list/agent");
        } else {
          Swal.fire({
            icon: "warning",
            title: response.data.message,
          });
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="px-6">
        <div className="flex space-x-6">
          <div className="">
            <h1 className="text-md text-slate-400 pt-8">User Name</h1>
            <input
              type="text"
              id="message"
              name="agent_username"
              value={inputs.agent_username || ""}
              onClick={() => Validation("agent_username")}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-[25rem] h-12 p-2.5 mt-2  dark:border-black dark:placeholder-gray-400 dark:text-white"
              placeholder="User Name"
              required
            />
            {required.username ? (
              <div className="text-red-500">*Please Enter Username</div>
            ) : (
              ""
            )}
          </div>

          <div className="">
            <h1 className="text-md text-slate-400 pt-8">Password</h1>
            <input
              type="text"
              id="agent_password"
              name="agent_password"
              value={inputs.agent_password || ""}
              onClick={() => Validation("agent_password")}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-[25rem] h-12 p-2.5 mt-2  dark:border-black dark:placeholder-gray-400 dark:text-white"
              placeholder="Password"
              required
            />
            {required.password ? (
              <div className="text-red-500">*Please Enter Password</div>
            ) : (
              ""
            )}
          </div>
          <div className="">
            <h1 className="text-md text-slate-400 pt-8">Phone Number</h1>
            <input
              type="text"
              id="phone_number"
              name="phone_number"
              value={inputs.phone_number || ""}
              onClick={() => Validation("phone_number")}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-[25rem] h-12 p-2.5 mt-2  dark:border-black dark:placeholder-gray-400 dark:text-white"
              placeholder="Phone Number"
              required
            />
            {required.phone_number ? (
              <div className="text-red-500">{error}</div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="flex space-x-6">
          <div className="">
            <h1 className="text-md text-slate-400 pt-8">Call Option</h1>
            <select
              name="call_option"
              value={inputs.call_option || ""}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-[25rem] h-12 p-2.5 mt-2  dark:border-black dark:placeholder-gray-400 dark:text-white"
              onChange={handleChange}
            >
              <option></option>
              <option value={"INCOMING"}>INCOMING</option>
              <option value={"OUTGOING"}>OUTGOING</option>
              <option value={"INCOMING & OUTGOING"}>
                INCOMING & OUTGOING
              </option>
            </select>
            {required.call_option ? (
              <div className="text-red-500">*Please Choose a Call Option</div>
            ) : (
              ""
            )}
          </div>
          <div className="">
            <h1 className="text-md text-slate-400 pt-8">Call Mode</h1>
            <select
              name="call_mode"
              value={inputs.call_mode || ""}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-[25rem] h-12 p-2.5 mt-2  dark:border-black dark:placeholder-gray-400 dark:text-white"
              onChange={handleChange}
            >
              <option></option>
              <option value={"mobile"}>Mobile</option>
              <option value={"sip"}>Sip</option>
            </select>
            {required.call_mode ? (
              <div className="text-red-500">*Please Choose a Call Mode</div>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="flex space-x-6">
          <div className="">
            <h1 className="text-md text-slate-400 pt-8">Sip Channel Id</h1>
            <select
              name="channel_id"
              value={inputs.channel_id || ""}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-[25rem] h-12 p-2.5 mt-2  dark:border-black dark:placeholder-gray-400 dark:text-white"
              onChange={handleChange}
            >
              <option>{inputs.channel_id || ""}</option>
              {channels.map((obj, index) => (
                <option value={obj.channel_id}>{obj.channel_id}</option>
              ))}
            </select>
            {required.call_option ? (
              <div className="text-red-500">*Please Choose a Channel Id</div>
            ) : (
              ""
            )}
          </div>
          {showdid ? (
            <div className="">
              <h1 className="text-md text-slate-400 pt-8">DID</h1>
              <select
                name="did"
                key={inputs.did}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-[25rem] h-12 p-2.5 mt-2  dark:border-black dark:placeholder-gray-400 dark:text-white"
                value={inputs.did || ""}
                onFocus={fetDIDData}
                onChange={handleChange}
              >
                <option>{inputs.did}</option>
                {did.map((obj, index) => (
                  <option value={obj.name}>{obj.name}</option>
                ))}
              </select>
              {required.call_option ? (
                <div className="text-red-500">*Please Choose a Did</div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>

        {inputs.call_mode == "sip" && (
          <div>
            <div className="">
              <h1 className="text-md text-slate-400 pt-8">
                Allowed IP Address
              </h1>
              <input
                type="text"
                id="message"
                name="allowed_ip"
                value={inputs.allowed_ip || ""}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-[25rem] h-12 p-2.5 mt-2  dark:border-black dark:placeholder-gray-400 dark:text-white"
                placeholder="Allowed IPAddress"
                onChange={handleChange}
                required
              />
            </div>
          </div>
        )}
        <div className="flex justify-end mr-[12rem]">
          <button
            className="font-bold text-lg rounded-full w-36 h-12"
            type="submit"
            style={{
              backgroundImage:
                "linear-gradient(177.88deg, #AFD462 1.79%, #6BC698 49.97%, #0FB1E2 82.06%)",
              color: "white",
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};

export default AddUser;
